<template>
  <div class="text-center">
    <v-dialog v-model="dialog">
      <v-layout child-flex>
        <v-card>
          <v-card-title class="text-h5 grey lighten-2 d-flex align-center">
            Work list
            <DataExport :data="formattedWorkList" :headers="headers" :additional-titles="additionalTitles"
              file-name="Yearly Program" />
          </v-card-title>

          <v-card-text>
            <v-data-table :footer-props="{ 'items-per-page-text':''}" :no-data-text="$t('No data available')" :headers="headers" :items="formattedWorkList" :items-per-page="100" dense
              class="elevation-1">
              <template v-slot:item.work_cost="{ item }">
                {{ item.work_cost | formatNumber }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top>
                  <template v-slot:[`activator`]="{ on, attrs }">
                    <v-icon color="blue" medium class="mr-2" v-bind="attrs" v-on="on" @click="showSoundnessPage(item)">
                      mdi-note-text-outline
                    </v-icon>
                  </template>
                  <span>{{ $t("popup-viewbridge") }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="$emit('close')"> Ok </v-btn>
          </v-card-actions>
        </v-card>
      </v-layout>
    </v-dialog>
  </div>
</template>

<script>
import { formatNumber } from "@/helpers";
import router from "@/router";

export default {
  name: "WorkListModal",
  props: ["workList", "dialog", "selectedYear", "currency"],
  filters: {
    formatNumber,
  },
  computed: {
    headers() {
      return [
        {
          text: "Work type",
          align: "right",
          value: "work_type",
        },
        {
          text: this.$t("bridge_name"),
          align: "left",
          value: "bridge_name",
        },
        {
          text: this.$t("roadnumber"),
          align: "left",
          value: "roadnumber",
        },
        {
          text: this.$t("linkid"),
          align: "left",
          value: "linkid",
        },
        {
          text: this.$t("chainage"),
          align: "left",
          value: "chainage",
        },
        {
          text: this.$t("river_name"),
          align: "left",
          value: "river_name",
        },
        {
          text: this.$t("bridge_gps_x"),
          align: "left",
          value: "bridge_gps_x",
        },
        {
          text: this.$t("bridge_gps_y"),
          align: "left",
          value: "bridge_gps_y",
        },
        {
          text: this.$t("bridge_province"),
          align: "left",
          value: "bridge_province",
        },
        {
          text: this.$t("bridge_district"),
          align: "left",
          value: "bridge_district",
        },
        {
          text: this.$t("span_number"),
          align: "left",
          value: "span_number",
        },
        {
          text: this.$t("component"),
          align: "left",
          value: "component",
        },
        {
          text: this.$t("element"),
          align: "left",
          value: "element",
        },
        {
          text: this.$t("repair_level"),
          align: "left",
          value: "repair_level",
        },
        {
          text: this.$t("pi"),
          align: "right",
          value: "pi",
        },
        {
          text: `${this.$t("cost")} (${this.currency})`,
          align: "right",
          value: "work_cost",
        },
        {
          text: "",
          align: "right",
          value: "actions",
        },
      ];
    },
    description() {
      return this.$i18n.locale === "la" ? "description_lao" : "description_eng";
    },
    additionalTitles() {
      return [
        ['Bridge Management System (BMS)'],
        ['Formulation of Annual Plan'],
        [`DETAILS WORK LIST`],
        [],
        ['Target Bridges:', 'As per selection'],
        [`Planning Year:`, this.selectedYear],
        [],
      ]
    },
    formattedWorkList() {
      return this.workList.map(work => ({
        ...work,
        component: this.displayComponents(work.component),
        element: this.displayElements(work.element),
        repair_level: this.displayCode('sys_repair_method', 'repair_level', work.repair_level),
        work_type: this.displayCode('element_scenario_soundness', 'event', work.work_type),
        bridge_province: this.getRegionName(work.bridge_province, 'bridge_province'),
        bridge_district: this.getRegionName(work.bridge_district, 'bridge_district'),
      }))
    }
  },
  methods: {
    displayComponents(code) {
      const sysCode = this.$store.state.sysCode.sysComponents.filter((x) => x.code === code);
      if (sysCode.length > 0) {
        return sysCode[0][this.description];
      }
      return code;
    },
    displayElements(code) {
      const sysCode = this.$store.state.sysCode.sysElements.filter((x) => x.code === code);
      if (sysCode.length > 0) {
        return sysCode[0][this.description];
      }
      return code;
    },
    getRegionName(region, type) {
      if (!region) {
        return '';
      }
      const listName = type === 'bridge_province' ? 'provincesList' : 'districtList';
      const data = this.$store.state.adm[listName].find(
        (x) => x.id === region
      );
      if (data) {
        return data[this.description];
      }
      return region;
    },
    displayCode(table, fld_name, code) {
      const sysCode = this.$store.state.sysCode.sysCodes.filter((x) => x.tbl_name == table && x.fld_name === fld_name && x.code === code);
      if (sysCode.length > 0) {
        return sysCode[0][this.description];
      }
      return code;
    },
    showSoundnessPage(item) {
      router.push({
        name: "bridge-soundness",
        params: { id: item.bridge_id },
      });
    },
  },
};
</script>

<style></style>