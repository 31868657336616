<template>
  <v-layout child-flex>
    <v-card class="ma-2 d-flex flex-column">
      <WorkListModal :work-list="workList" :selected-year="selectedYear" :currency="currency" :dialog="dialog" @close="closeDialog()" />
      <v-toolbar flat>
        <v-toolbar-title class="d-flex align-center">Analysis for scenario {{ this.scenarioNumber }} and selected
          constraints

          <DataExport :data="rawSeries" :headers="headers" :additional-titles="additionalTitles"
            file-name="Yearly Program" />
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-data-table :footer-props="{ 'items-per-page-text':''}" :no-data-text="$t('No data available')" :headers="headers" :items="rawSeries" :items-per-page="100" dense hide-default-footer
        class="elevation-1">
        <template v-slot:item.yearlyBudget="{ item }">
          {{ item.yearlyBudget | formatNumber }}
        </template>
        <template v-slot:item.REPSM="{ item }">
          {{ item.REPSM | formatNumber }}
        </template>
        <template v-slot:item.REPMD="{ item }">
          {{ item.REPMD | formatNumber }}
        </template>
        <template v-slot:item.REPLA="{ item }">
          {{ item.REPLA | formatNumber }}
        </template>
        <template v-slot:item.year_total_cost="{ item }">
          {{ item.year_total_cost | formatNumber }}
        </template>
        <template v-slot:item.cumulative_cost="{ item }">
          {{ item.cumulative_cost | formatNumber }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top>
            <template v-slot:[`activator`]="{ on, attrs }">
              <v-icon color="blue" medium class="mr-2" v-bind="attrs" v-on="on" @click="showWorks(item)">
                mdi-note-text-outline
              </v-icon>
            </template>
            <span>View works</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </v-layout>
</template>



<script>
import BridgeService from "@/services/BridgeService";
import { formatNumber, getCumulativeFromWorkQuery } from "@/helpers";
import WorkListModal from "@/components/analysis/WorkListModal.vue";

export default {
  name: "YearlyProgram",
  components: {
    WorkListModal,
  },
  filters: {
    formatNumber,
  },
  data() {
    return {
      yearlyBudget: null,
      dialog: false,
      workList: [],
      constrainedWorksPayload: [],
      selectedYear: 0,
    };
  },
  props: {
    analysis: {
      type: Object,
    },
    currency: {
      type: String,
    },
  },
  mounted() {
    this.getConstrainedWork();
  },
  computed: {
    analysisId() {
      return Number(this.$route.params.id);
    },
    budget() {
      return this.analysis.YearlyBudgets;
    },
    startYear() {
      return Number(this.analysis.start_year);
    },
    scenarioNumber() {
      return Number(this.analysis.scenario_number);
    },
    headers() {
      return [
        {
          text: this.$t("year"),
          align: "right",
          value: "year",
        },
        {
          text: `${this.$t("yearly-budget")} (${this.currency})`,
          align: "right",
          value: "yearlyBudget",
        },
        {
          text: `${this.$t("small-repair")} (${this.currency})`,
          align: "right",
          value: "REPSM",
        },
        {
          text: `${this.$t("medium-repair")} (${this.currency})`,
          align: "right",
          value: "REPMD",
        },
        {
          text: `${this.$t("large-repair")} (${this.currency})`,
          align: "right",
          value: "REPLA",
        },
        {
          text: `${this.$t("Year cost")} (${this.currency})`,
          align: "right",
          value: "year_total_cost",
        },
        {
          text: `${this.$t("Cumulative cost")} (${this.currency})`,
          align: "right",
          value: "cumulative_cost",
        },
        {
          text: this.$t("Replace"),
          align: "right",
          value: "number_recon",
        },
        {
          text: "",
          align: "right",
          value: "actions",
        },
      ];
    },
    additionalTitles() {
      return [
        ['Bridge Management System (BMS)'],
        ['Formulation of Medium-Term Plan'],
        [`SUMMARY OF YEARLY PROGRAM`],
        [],
        ['Target Bridges: As per selection'],
        [`Planning Period: ${this.startYear}-${this.startYear + 5}`],
        [],
      ]
    },
    escalatedConstrainedWorksPayload() {
      if(!this.analysis.escalation_rate || !this.analysis.start_year) {
        return this.constrainedWorksPayload;
      }
      const rate = this.analysis.escalation_rate;
      const start = this.analysis.start_year;
      const exchange = this.currency === 'LAK' ? 1 : this.analysis.exchange_rate;
      return this.constrainedWorksPayload.map(x => ({
        ...x,
        work_cost: x.work_cost * (1 + rate/100)**(x.year - start) / exchange
      }))
    },
    rawSeries() {
      if(!this.analysis) {
        return []
      }
      return getCumulativeFromWorkQuery(
        this.escalatedConstrainedWorksPayload,
        this.analysis.YearlyBudgets.length,
        Number(this.startYear)
      ).map((x) => {
        const year = x.year;
        const budgetYear = this.budget.find((x) => x.year == year);
        const yearlyBudget = budgetYear ? budgetYear.budget : "-";
        return { ...x, yearlyBudget };
      });
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    async showWorks(item) {
      this.workList = this.escalatedConstrainedWorksPayload.filter(
        (x) => x.year === item.year
      );
      this.selectedYear = item.year;
      this.dialog = true;
    },
    async getConstrainedWork() {
      this.constrainedWorksPayload = (
        await BridgeService.getConstrainedWork(this.analysisId)
      ).data;

      
    },
  },
};
</script>
