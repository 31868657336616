var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-2 pa-2 d-flex flex-column"},[_c('SixScenarioAnalysis',{attrs:{"payload":_vm.payload,"analysis":_vm.analysis,"currency":_vm.currency,"no-select":""}}),_c('v-card',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex flex-wrap"},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Work cost to HI")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1),_c('div',{staticClass:"ma-2"},_vm._l((_vm.scenarios),function(scenario){return _c('v-btn',{key:scenario.scenario_number,staticClass:"ma-2",attrs:{"color":_vm.selectedScenarioNumber == scenario.scenario_number
          ? 'green'
          : 'grey',"small":"","rounded":""},on:{"click":function($event){_vm.selectedScenarioNumber = scenario.scenario_number}}},[_vm._v(_vm._s(scenario.scenario_number)+" - "+_vm._s(scenario[_vm.sysCodeLanguage]))])}),1)],1),_c('apexchart',{attrs:{"type":"line","options":_vm.chartOptions,"series":_vm.series,"height":600}})],1),_c('v-layout',{attrs:{"child-flex":""}},[_c('v-card',{staticClass:"my-2"},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"d-flex align-center"},[_vm._v(_vm._s(_vm.$t("Work cost details"))+" "),_c('DataExport',{attrs:{"data":_vm.rawSeries,"headers":_vm.headers,"additional-titles":_vm.additionalTitles,"file-name":"LCC_analysis"}})],1),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1),_c('v-data-table',{attrs:{"footer-props":{ 'items-per-page-text':''},"no-data-text":_vm.$t('No data available'),"headers":_vm.headers,"items":_vm.rawSeries,"items-per-page":100,"dense":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.hi",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.hi ? item.hi : 0))+" ")]}},{key:"item.REPSM",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.REPSM))+" ")]}},{key:"item.REPMD",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.REPMD))+" ")]}},{key:"item.REPLA",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.REPLA))+" ")]}},{key:"item.year_total_cost",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.year_total_cost ? item.year_total_cost : 0))+" ")]}},{key:"item.replace",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.year > 2050 ? Math.floor(10 * Math.random()) : 0)+" ")]}},{key:"item.cumulative_cost",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.cumulative_cost))+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }