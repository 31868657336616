<template>
  <div class="d-flex">
    <v-layout child-flex>
      <v-card class="flex-1">
        <v-toolbar flat>
          <v-toolbar-title>{{ $t('Aggregated cost') }} {{ analysis.start_year }} - {{  analysis.start_year + analysis.number_of_years }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-data-table :footer-props="{ 'items-per-page-text':''}" :no-data-text="$t('No data available')" :headers="headers" :items="totalScenarioCosts" v-model="selectedScenarios" :show-select="!noSelect"
          single-select :items-per-page="10" hide-default-footer>
          <template v-slot:item.scenario_number="{ item }">
            <v-chip :color="getSeriesColors(`SCENARIO_${item.scenario_number}`)">
              {{ item.scenario_number }}
            </v-chip>
          </template>
          <template v-slot:item.total_cost="{ item }">
            <v-chip :color="item.total_cost == minCost ? 'green' : 'grey'">
              {{ item.total_cost ? item.total_cost : 0 | formatNumber }}
            </v-chip>
          </template>
        </v-data-table>
      </v-card>
    </v-layout>

    <v-card class="flex-2 ml-1">
      <v-toolbar flat>
        <v-toolbar-title>{{ $t("Cumulative cost") }}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
      </v-toolbar>
      <apexchart v-if="series.length" type="line" :options="chartOptions" :series="series" :height="400" width="100%">
      </apexchart>
    </v-card>
  </div>
</template>

<script>
import store from "@/store";
import { formatNumber, getSeriesColors } from "@/helpers";

export default {
  name: "SixScenarioAnalysis",
  filters: {
    formatNumber,
  },
  data() {
    return {
      selectedScenarios: [],
    };
  },
  computed: {
    scenarios() {
      return store.state.bridge.scenarios.filter((s) => s.scenario_number > 0);
    },
    sysCodeLanguage() {
      return this.$i18n.locale === "la" ? "description_lao" : "description_eng";
    },
    headers() {
      return [
        {
          text: "",
          align: "right",
          value: "scenario_number",
          sortby: true,
        },
        {
          text: this.$t("scenario"),
          align: "right",
          value: this.sysCodeLanguage,
          sortby: true,
        },
        {
          text: `${this.$t("cost")} (${this.currency})`,
          align: "right",
          value: "total_cost",
          sortby: true,
        },
      ];
    },
    minCost() {
      return Math.min(...this.totalScenarioCosts.map((x) => x.total_cost));
    },
    totalScenarioCosts() {
      if (this.payload.length === 0) {
        return [];
      }
      return this.scenarios.map((s) => ({
        id: s.scenario_number,
        scenario_number: s.scenario_number,
        description_eng: s.description_eng,
        description_lao: s.description_lao,
        total_cost: this.payload.filter(
          (x) => x.scenario_number === s.scenario_number
        ).reduce((acc, p) => {
          return acc + (isNaN(p.year_total_cost) || !p.year_total_cost ? 0 : p.year_total_cost)
        }, 0),
      }));
    },
    chartOptions() {
      const categories = [...new Set(this.payload
        .filter((x) => x.scenario_number === 1)
        .map((x) => x.year))];

      return {
        chart: {
          animations: {
            enabled: false,
          },
        },
        xaxis: {
          categories,
          labels: {
            formatter: function (value) {
              if (value % 5 === 0) {
                return value;
              }
              return "";
            },
          },
        },
        tooltip: {
          x: {
            formatter: function (value, { dataPointIndex }) {
              return categories[dataPointIndex];
            },
          },
        },
        markers: {
          size: 3,
        },
        yaxis: [
          {
            min: 0,
            labels: {
              formatter: function (val) {
                return formatNumber(val);
              },
            },
          },
        ],
      };
    },
    series() {
      return this.scenarios
        .map((s) => s.scenario_number)
        .map((s) => ({
          name: `Scenario ${s}`,
          color: getSeriesColors(`SCENARIO_${s}`),
          data: [... new Set(this.payload
            .filter((x) => x.scenario_number === s)
            .map((x) => x.cumulative_cost))],
        }));
    },
  },
  props: {
    payload: {
      type: Array,
      default() {
        return []
      }
    },
    currency: {
      type: String,
    },
    analysis: {
      type: Object,
    },
    noSelect: {
      type: Boolean,
    },
    draft: {
      type: Boolean,
    }
  },
  mounted() {
    this.selectedScenarios = [this.analysis.scenario_number];
  },
  watch: {
    totalScenarioCosts: {
      handler() {
        if (this.analysis?.scenario_number) {
          this.selectedScenarios = this.totalScenarioCosts.filter(
            (s) => s.scenario_number === this.analysis.scenario_number
          );
        }
      },
      immediate: true,
    },
    selectedScenarios: {
      handler(selectedScenarios) {
        if (selectedScenarios.length && selectedScenarios[0]) {
          this.$emit(
            "update:selectedScenario",
            selectedScenarios[0].scenario_number
          );
        } else {
          this.$emit("update:selectedScenario", null);
        }
      },
      immediate: true,
    },
  },
  methods: {
    getSeriesColors,
  },
};
</script>

<style></style>