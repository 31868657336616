var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('AreYouSureModal',{attrs:{"resource":"analysis","prefix":"custom","id":_vm.selectedId,"dialog":_vm.deleteDialog},on:{"toggle":_vm.toggleModal}}),_c('div',{staticClass:"d-flex flex-column"},[_c('v-layout',{attrs:{"child-flex":""}},[_c('v-card',{staticClass:"ma-2 d-flex flex-column"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":{ 'items-per-page-text':''},"no-data-text":_vm.$t('No data available'),"headers":_vm.headers,"items":_vm.draftAnalysis,"items-per-page":15},scopedSlots:_vm._u([_vm._l((_vm.headers),function(header){return {key:("item." + (header.value)),fn:function(ref){
var item = ref.item;
return [_c('LinkToResource',{key:header.value,attrs:{"item":item,"route":"analysis-details"}},[_vm._v(_vm._s(item[header.value])+" ")])]}}}),{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("Draft analysis")))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),(_vm.$store.getters.get_access('module_analysis', 1))?_c('v-btn',{attrs:{"color":"green","dark":""},on:{"click":_vm.newAnalysis}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.$t("New analysis")))],1):_vm._e()],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([(_vm.$store.getters.get_access('module_analysis', 1))?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"blue","medium":""},on:{"click":function($event){return _vm.showDetails(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-note-text-outline ")])]}}:null],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("View analysis")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([(_vm.$store.getters.get_access('module_analysis', 3) || item.creator_id === _vm.userId)?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"blue","medium":""},on:{"click":function($event){return _vm.toggleModal(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}:null],null,true)},[_c('span',[_vm._v("Delete analysis")])])]}}],null,true)})],1)],1),_c('v-layout',{attrs:{"child-flex":""}},[_c('v-card',{staticClass:"ma-2 d-flex flex-column"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":{ 'items-per-page-text':''},"no-data-text":_vm.$t('No data available'),"headers":_vm.headers,"items":_vm.publicAnalysis,"items-per-page":15},scopedSlots:_vm._u([_vm._l((_vm.headers),function(header){return {key:("item." + (header.value)),fn:function(ref){
var item = ref.item;
return [_c('LinkToResource',{key:header.value,attrs:{"item":item,"route":"analysis-details"}},[_vm._v(_vm._s(item[header.value])+" ")])]}}}),{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("Public analysis")))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-switch',{attrs:{"label":"Show archived"},model:{value:(_vm.showArchived),callback:function ($$v) {_vm.showArchived=$$v},expression:"showArchived"}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"blue","medium":""},on:{"click":function($event){return _vm.showDetails(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-note-text-outline ")])]}}],null,true)},[_c('span',[_vm._v("View analysis")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([(_vm.$store.getters.get_access('module_analysis', 3))?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"blue","medium":""},on:{"click":function($event){return _vm.toggleModal(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}:null],null,true)},[_c('span',[_vm._v("Delete analysis")])])]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }