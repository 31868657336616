<template>
  <v-layout justify-center>

    <v-flex  column>
      <v-card class="ma-4">
        <v-toolbar flat>
          <v-toolbar-title>Profile Page</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
        </v-toolbar>
        <div class="pa-2"><span bold>Username :</span> {{this.$store.state.authentication.user.username}}</div>
        <div class="pa-2"><span bold>Mail :</span> {{this.$store.state.authentication.user.email}}</div>
        <div class="pa-2"><span bold>Language :</span> {{this.$store.state.authentication.user.language}}</div>
        <div class="pa-2"><v-btn color="green" dark @click="showForm()"> <v-icon small class="pr-2">mdi-pencil</v-icon> Change password</v-btn></div>
      </v-card>
    </v-flex>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Change Password</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <slot name="edition">
              <v-text-field
                v-model="new_password"
                label="New password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                @click:append="show1 = !show1"
              ></v-text-field>
              <v-text-field
                v-model="confirm_password"
                label="Confirm password"
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show2 ? 'text' : 'password'"
                @click:append="show2 = !show2"
              ></v-text-field>
              <p  style="color:red;">{{dialogMessage}}</p>
            </slot>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
          <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="afterPasswordDialog" max-width="500px">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <p :class="statusMessageType">{{statusMessage}}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
  </v-layout>
</template>

<script>
import UserService from "@/services/UserService.js";

export default {
  data() {
    return {
      user: {},
      show: false,
      dialog: false,
      loading: false,
      new_password: "",
      confirm_password: "",
      dialogMessage: "",
      statusMessage: "",
      statusMessageType: "",
      afterPasswordDialog: false,
      show1: false,
      show2: false,
    };
  },
  async mounted() {
    this.user = (
      await UserService.get(this.$store.state.authentication.user.id)
    ).data;
  },
  computed: {
    locales() {
      return ['en','la'];
    },
  },
  methods: {
    showForm(){
      this.dialog=true;
    },
    close() {
      this.dialog = false;
      this.dialogMessage = "";
      this.afterPasswordDialog = false;
    },
    async save(){
      try{
        if(this.new_password.length > 0 && this.confirm_password.length > 0){
          if(this.new_password === this.confirm_password){
            let edition = Object.assign({}, {'password': this.new_password, 'id': this.$store.state.authentication.user.id});
            UserService.update(edition);
            this.close();
            this.statusMessage = "Password successfully changed !";
            this.statusMessageType = "good_message";
            this.afterPasswordDialog = true;
          } else {
            this.dialogMessage = "The two text fields must match";
            this.dialogMessageType = "error_message";
          }
        } else {
          this.dialogMessage = "Please fill both text fields";
          this.dialogMessageType = "error_message";

        }
      } catch (err){
        this.statusMessage = "There was an error while changing password";
        this.statusMessageType = "error_message";
        console.log(err);
      }
    }
  },

};
</script>

<style scoped>
.error_message {
  color: red;
  font-size: 15px;
}
.good_message {
  color: green;
  font-size: 15px;
}
</style>
