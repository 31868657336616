<template>
  <v-container fluid>
    <div class="d-flex flex-column">
      <v-card class="ma-2 pa-2">
        <v-toolbar flat>
          <v-toolbar-title>{{ $t("Analysis details") }}<v-progress-circular v-if="loading" class="ma-2"
              indeterminate></v-progress-circular></v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
        </v-toolbar>
        <div class="d-flex justify-space-between full-width">
          <div class="d-flex align-center">
            <v-text-field @input="save" v-model="analysis.description" :label="$t('description')" class="mx-2 long-field"
              :readonly="!canEdit"></v-text-field>

            <v-text-field @input="save" v-model="analysis.start_year" type="number" :label="$t('start_year')" class="mx-2"
              :readonly="!canEdit"></v-text-field>

            <v-text-field @input="save" v-model="analysis.number_of_years" type="number" :label="$t('number_of_years')"
              class="mx-2" :readonly="!canEdit"></v-text-field>

            <v-text-field @input="save" v-model="analysis.escalation_rate" type="number" :label="$t('escalation_rate')"
              class="mx-2" :readonly="!canEdit"></v-text-field>

            <v-checkbox v-model="analysis.draft" :label="$t('draft')"
              v-if="$store.getters.get_access('module_analysis', 2)"></v-checkbox>
          </div>
        </div>
        <div class="d-flex justify-space-between full-width">
          <div class="d-flex align-center">
            <v-text-field @input="save" v-model="analysis.additional_currency" :label="$t('additional_currency')"
              class="mx-2" :readonly="!canEdit"></v-text-field>

            <v-text-field @input="save" v-model="analysis.exchange_rate" type="number" :label="$t('exchange_rate')"
              class="mx-2" :readonly="!canEdit"></v-text-field>
            
            <v-switch :label="$t('Use other currency')" v-model="useOtherCurrency" :disabled="!analysis.exchange_rate"></v-switch>
          </div>
        </div>
      </v-card>
      <v-card class="ma-2">
        <v-toolbar flat>
          <v-tabs v-model="selectedTab" align-with-title background-color="grey lighten-4">
            <v-tabs-slider color="red"></v-tabs-slider>
            <v-tab v-for="tab in tabs" :key="tab.key" :disabled="tab.disabled">
              {{ tab.label }}
            </v-tab>
          </v-tabs>
        </v-toolbar>
        <v-tabs-items v-model="selectedTab" class="mt-4 pa-2 full-width">
          <v-tab-item key="bridges-tab" :transition="false">
            <BridgeSelector :analysis="analysis" :selected-bridges.sync="selectedBridges" :canEdit="canEdit" />
          </v-tab-item>
          <v-tab-item key="LCC-6scenarios-tab" :transition="false">
            <ScenarioAnalysis :analysis="analysis" :payload="escalatedPayload" :currency="currency" :canEdit="canEdit" />
          </v-tab-item>
          <v-tab-item key="budget-constraints-tab" :transition="false">
            <BudgetConstraints v-if="analysis" :analysis="analysis" :internal-analysis.sync="budgetAnalysis"
              :yearly-budgets.sync="yearlyBudgets" :payload="escalatedPayload" :currency="currency" :selected-scenario.sync="selectedScenario"
              :canEdit="canEdit" />
          </v-tab-item>
          <v-tab-item key="yearly-program-tab" :transition="false" v-if="selectedTab === 3">
            <YearlyProgram :analysis="analysis" :currency="currency"/>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
  </v-container>
</template>


<script>
import BridgeService from "@/services/BridgeService";
import router from "@/router";
import BridgeSelector from "@/components/analysis/BridgeSelector.vue";
import BudgetConstraints from "@/components/analysis/BudgetConstraints.vue";
import ScenarioAnalysis from "@/components/analysis/ScenarioAnalysis.vue";
import YearlyProgram from "@/components/analysis/YearlyProgram.vue";
import {
  getCumulativeFromWorkQuery,
} from "@/helpers";

export default {
  name: "TheAnalysisDetails",
  components: {
    BridgeSelector,
    BudgetConstraints,
    ScenarioAnalysis,
    YearlyProgram,
  },
  data() {
    return {
      loading: false,
      useOtherCurrency: false,
      saveTimeout: null,
      selectedTab: null,
      saveLoading: false,
      calculating: false,
      payload: [],
      analysis: {
        start_year: null,
        number_of_years: null,
        draft: true,
        AnalysisBridges: [],
        YearlyBudgets: [],
      },
      budgetAnalysis: {},
      selectedBridges: [],
      yearlyBudgets: [],
      selectedScenario: null,
      initialSelectedScenario: null,
      initialSelectedBridges: [],
    };
  },
  computed: {
    currency() {
      if(this.useOtherCurrency && this.analysis.exchange_rate) {
        return this.analysis.additional_currency
      }
      return 'LAK'
    },
    startYear() {
      return Number(this.analysis.start_year);
    },
    numberOfYears() {
      return Number(this.analysis.number_of_years);
    },
    scenarios() {
      return this.$store.state.bridge.scenarios.filter((s) => s.scenario_number > 0);
    },
    analysisId() {
      return Number(this.$route.params.id);
    },
    tabs() {
      return [
        {
          key: "bridges-tab",
          label: this.$t("bridges-tab"),
          disabled: false,
        },
        {
          key: "LCC-6scenarios-tab",
          label: this.$t("LCC-scenarios"),
          disabled: false,
        },
        {
          key: "budget-constraints-tab",
          label: this.$t("budget-constraints"),
          disabled: !this.analysis.id || !this.analysis.start_year,
        },
        {
          key: "yearly-program-tab",
          label: this.$t("Yearly program"),
          disabled:
            this.analysis.YearlyBudgets?.length === 0 ||
            !this.analysis.scenario_number,
        },
      ];
    },
    hasBrowse() {
      return this.$store.getters.get_access("module_analysis", 1);
    },
    hasEdit() {
      return this.$store.getters.get_access("module_analysis", 2);
    },
    hasAdmin() {
      return this.$store.getters.get_access("module_analysis", 3);
    },
    canEdit() {
      return (this.hasEdit && this.analysis.draft) || this.hasAdmin || this.userId === this.analysis.creator_id;
    },
    userId() {
      return Number(this.$store.state.authentication.user.id)
    },
    escalatedPayload() {
      if (!this.analysis.escalation_rate || !this.analysis.start_year) {
        return this.payload;
      }
      const rate = this.analysis.escalation_rate;
      const start = this.analysis.start_year;
      const exchange = this.currency === 'LAK' ? 1 : this.analysis.exchange_rate;
      return this.payload.map(x => ({
        ...x,
        year_total_cost: x.year_total_cost * (1 + rate / 100) ** (x.year - start) / exchange,
        REPLA: x.REPLA * (1 + rate / 100) ** (x.year - start) / exchange,
        REPMD: x.REPMD * (1 + rate / 100) ** (x.year - start) / exchange,
        REPSM: x.REPSM * (1 + rate / 100) ** (x.year - start) / exchange,
        cumulative_cost: x.cumulative_cost * (1 + rate / 100) ** (x.year - start) / exchange,
      }))
    }
  },
  watch: {
    analysisId: {
      async handler(analysisId) {
        if (analysisId) {
          this.analysis = (await BridgeService.getAnalysis(analysisId)).data;
          this.initialSelectedScenario = this.analysis.scenario_number;
          this.getScenarioAnalysis();
        }
      },
      immediate: true,
    },
    startYear: {
      handler() {
        this.getScenarioAnalysis();
      },
      immediate: false,
    },
    numberOfYears: {
      handler() {
        this.getScenarioAnalysis();
      },
      immediate: false,
    },
    budgetAnalysis: {
      handler() {
        this.analysis.element_hi_weight = this.budgetAnalysis.element_hi_weight;
        this.analysis.element_importance_weight =
          this.budgetAnalysis.element_importance_weight;
        this.analysis.road_importance_weight =
          this.budgetAnalysis.road_importance_weight;
        this.analysis.traffic_volume_weight =
          this.budgetAnalysis.traffic_volume_weight;
        this.analysis.economic_impact_weight =
          this.budgetAnalysis.economic_impact_weight;
        this.save();
      },
      deep: true,
    },
    selectedBridges: {
      handler() {
        this.analysis.AnalysisBridges = this.selectedBridges.map((b) => ({
          bridge_id: b.id,
          analysis_id: this.analysisId,
        }));
        this.save();
        this.getScenarioAnalysis();
      },
    },
    yearlyBudgets: {
      handler() {
        this.analysis.YearlyBudgets = this.yearlyBudgets.map((b) => ({
          year: b.year,
          budget: Number(String(b.budget).replaceAll(",", "")),
          analysis_id: this.analysisId,
        }));
        this.save();
      },
      deep: true,
    },
    selectedScenario: {
      handler() {
        this.analysis.scenario_number = this.selectedScenario;
        this.save();
      },
    },
  },
  methods: {
    async getScenarioAnalysis() {
      this.loading = true;
      if (
        this.analysisId && this.numberOfYears && this.startYear
      ) {
        const payload = (
          await BridgeService.getScenarioAnalysis(
            this.analysisId
          )
        ).data;

        this.payload = this.scenarios
          .map((s) => {
            return getCumulativeFromWorkQuery(
              payload.filter(x => x.scenario_number === s.scenario_number),
              Number(this.numberOfYears),
              Number(this.startYear)
            );
          }).flat()
      }
      this.loading = false;
    },
    async save() {
      if (this.saveTimeout) clearTimeout(this.saveTimeout);
      this.saveTimeout = setTimeout(async () => {
        const savedItem = (await BridgeService.saveAnalysis(this.analysis))
          .data;
        this.analysis = savedItem;
        if (!this.analysisId) {
          router.push({
            name: "analysis-details",
            params: { id: savedItem.id },
          });
        }
      }, 300);
    },
  },
};
</script>


<style scoped>
.main-card {
  min-height: 85vh;
}

.tabs {
  height: 100px;
}

.long-field {
  width: 500px;
}
</style>