<template>
  <div class="ma-2 pa-2 d-flex flex-column align-start">
    <div class="d-flex justify-space-around full-width">
      <v-btn class="ma-4" fab @click="addToBeSelected" v-if="canEdit">
        <v-icon> mdi-arrow-right </v-icon>
      </v-btn>
      <v-btn class="ma-4" fab @click="removeToBeNotSelected" v-if="canEdit">
        <v-icon> mdi-arrow-left </v-icon>
      </v-btn>
    </div>
    <div class="d-flex justify-space-between full-width">
      <v-layout child-flex class="pa-2">
        <div>
          <v-card-title>
            <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('Search')" single-line
              hide-details></v-text-field>
          </v-card-title>
          <v-data-table :footer-props="{ 'items-per-page-text':''}" :no-data-text="$t('No data available')" :headers="headers" :items="notSelectedBridges" v-model="toBeSelected" show-select
            :items-per-page="15" sort-by="id" :sort-desc="false" class="elevation-1" :search="search" dense>
            <template v-slot:[`item.actions`]="{ item }">
              <GoToBridge :id="item.id" />
            </template>
          </v-data-table>
        </div>
      </v-layout>
      <v-layout child-flex class="pa-2">
        <div>
          <v-card-title>
            <v-text-field v-model="search2" append-icon="mdi-magnify" :label="$t('Search')" single-line
              hide-details></v-text-field>
          </v-card-title>
          <v-data-table :footer-props="{ 'items-per-page-text':''}" :no-data-text="$t('No data available')" :headers="headers" :items="internalSelectedBridges" v-model="toBeNotSelected" show-select
            :items-per-page="15" sort-by="id" :sort-desc="false" class="elevation-1" :search="search2" dense>
            <template v-slot:[`item.actions`]="{ item }">
              <GoToBridge :id="item.id" />
            </template>
          </v-data-table>
        </div>
      </v-layout>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import GoToBridge from "@/components/main/GoToBridge.vue";

export default {
  name: "BridgeSelector",
  components: {
    GoToBridge,
  },
  data() {
    return {
      notSelectedBridges: [],
      toBeSelected: [],
      internalSelectedBridges: [],
      toBeNotSelected: [],
      search: "",
      search2: "",
    };
  },
  props: ["analysis", "canEdit"],
  mounted() {
    this.notSelectedBridges = [...this.bridges];
    const idsSelected = this.internalSelectedBridges.map((b) => b.id);
    this.notSelectedBridges = this.notSelectedBridges.filter(
      (b) => !idsSelected.includes(b.id)
    );
  },
  watch: {
    analysis: {
      handler() {
        this.internalSelectedBridges = [
          ...this.analysis.AnalysisBridges.map((x) => x.Bridge),
        ];
        const idsSelected = this.internalSelectedBridges.map((b) => b.id);
        this.notSelectedBridges = this.notSelectedBridges.filter(
          (b) => !idsSelected.includes(b.id)
        );
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState("bridge", ["bridges"]),
    headers() {
      return [
        {
          text: this.$t("header-road"),
          value: "roadnumber",
          sortby: true,
          color: "blue",
          align: "center",
        },
        {
          text: this.$t("header-link"),
          value: "linkid",
          sortby: true,
          color: "blue",
          align: "center",
        },
        {
          text: this.$t("header-chainage"),
          value: "chainage",
          sortby: true,
          color: "blue",
          align: "center",
        },
        {
          text: this.$t("header-bridgename"),
          value: "bridge_name",
          sortby: true,
          color: "blue",
          align: "left",
        },
        {
          text: this.$t("header-province"),
          value: "bridge_province",
          sortby: true,
          color: "blue",
          align: "left",
        },
        {
          text: this.$t("header-district"),
          value: "bridge_district",
          sortby: true,
          color: "blue",
          align: "left",
        },
        {
          text: "",
          value: "actions",
          sortby: true,
          color: "blue",
          align: "left",
        },
      ];
    },
  },
  methods: {
    addToBeSelected() {
      this.internalSelectedBridges.push(...this.toBeSelected);
      const idsToBeSelected = this.internalSelectedBridges.map((b) => b.id);
      this.notSelectedBridges = this.notSelectedBridges.filter(
        (b) => !idsToBeSelected.includes(b.id)
      );
      this.toBeSelected = [];
      this.$emit("update:selectedBridges", this.internalSelectedBridges);
    },
    removeToBeNotSelected() {
      this.notSelectedBridges.push(...this.toBeNotSelected);
      const idsToBeNotSelected = this.notSelectedBridges.map((b) => b.id);
      this.internalSelectedBridges = this.internalSelectedBridges.filter(
        (b) => !idsToBeNotSelected.includes(b.id)
      );
      this.toBeNotSelected = [];
      this.$emit("update:selectedBridges", this.internalSelectedBridges);
    },
  },
};
</script>

<style></style>