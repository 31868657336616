<template>
  <v-tooltip top>
    <template v-slot:[`activator`]="{ on, attrs }">
      <v-icon color="blue" medium class="mr-2" v-bind="attrs" v-on="on" @click="showInventoryDetail(id)">
        mdi-note-text-outline
      </v-icon>
    </template>
    <span>{{ $t("popup-viewbridge") }}</span>
  </v-tooltip>
</template>


<script>
import router from "@/router";

export default {
  name: "bridge-list",
  props: ["id"],
  methods: {
    showInventoryDetail(id) {
      router.push({
        name: "bridge-inventory",
        params: { id },
      });
    },
  },
};
</script>

<style></style>