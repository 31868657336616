var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"child-flex":""}},[_c('v-card',{staticClass:"ma-2 d-flex flex-column"},[_c('WorkListModal',{attrs:{"work-list":_vm.workList,"selected-year":_vm.selectedYear,"currency":_vm.currency,"dialog":_vm.dialog},on:{"close":function($event){return _vm.closeDialog()}}}),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"d-flex align-center"},[_vm._v("Analysis for scenario "+_vm._s(this.scenarioNumber)+" and selected constraints "),_c('DataExport',{attrs:{"data":_vm.rawSeries,"headers":_vm.headers,"additional-titles":_vm.additionalTitles,"file-name":"Yearly Program"}})],1),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":{ 'items-per-page-text':''},"no-data-text":_vm.$t('No data available'),"headers":_vm.headers,"items":_vm.rawSeries,"items-per-page":100,"dense":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.yearlyBudget",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.yearlyBudget))+" ")]}},{key:"item.REPSM",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.REPSM))+" ")]}},{key:"item.REPMD",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.REPMD))+" ")]}},{key:"item.REPLA",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.REPLA))+" ")]}},{key:"item.year_total_cost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.year_total_cost))+" ")]}},{key:"item.cumulative_cost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.cumulative_cost))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"blue","medium":""},on:{"click":function($event){return _vm.showWorks(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-note-text-outline ")])]}}],null,true)},[_c('span',[_vm._v("View works")])])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }