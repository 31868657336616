<template>
  <div class="ma-2 pa-2 d-flex flex-column">
    <SixScenarioAnalysis :payload="payload" :analysis="analysis"  :currency="currency"
      no-select />
    <v-card class="d-flex flex-column">
      <div class="d-flex flex-wrap">
        <v-toolbar flat>
          <v-toolbar-title>Work cost to HI</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
        </v-toolbar>
        <div class="ma-2">
          <v-btn v-for="scenario in scenarios" :key="scenario.scenario_number" class="ma-2" :color="selectedScenarioNumber == scenario.scenario_number
            ? 'green'
            : 'grey'
            " small rounded @click="selectedScenarioNumber = scenario.scenario_number">{{ scenario.scenario_number }} -
            {{ scenario[sysCodeLanguage] }}</v-btn>
        </div>
      </div>
      <apexchart type="line" :options="chartOptions" :series="series" :height="600"></apexchart>
    </v-card>
    <v-layout child-flex>
      <v-card class="my-2">
        <v-toolbar flat>
          <v-toolbar-title class="d-flex align-center">{{ $t("Work cost details") }}
            <DataExport :data="rawSeries" :headers="headers" :additional-titles="additionalTitles" file-name="LCC_analysis"/>
          </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-data-table :footer-props="{ 'items-per-page-text':''}" :no-data-text="$t('No data available')" :headers="headers" :items="rawSeries" :items-per-page="100" dense hide-default-footer>
          <template v-slot:item.hi="{ item }">
            {{ item.hi ? item.hi : 0 | formatNumber }}
          </template>
          <template v-slot:item.REPSM="{ item }">
            {{ item.REPSM | formatNumber }}
          </template>
          <template v-slot:item.REPMD="{ item }">
            {{ item.REPMD | formatNumber }}
          </template>
          <template v-slot:item.REPLA="{ item }">
            {{ item.REPLA | formatNumber }}
          </template>
          <template v-slot:item.year_total_cost="{ item }">
            {{ item.year_total_cost ? item.year_total_cost : 0 | formatNumber }}
          
          </template>
          <template v-slot:item.replace="{ item }">
            {{ item.year > 2050 ? Math.floor(10 * Math.random()) : 0 }}
          </template>

          <template v-slot:item.cumulative_cost="{ item }">
            {{ item.cumulative_cost | formatNumber }}
          </template>
        </v-data-table>
      </v-card>
    </v-layout>
  </div>
</template>



<script>
import SixScenarioAnalysis from "@/components/analysis/SixScenarioAnalysis.vue";

import {
  formatNumber,
  getSeriesColors,
} from "@/helpers";
import store from "@/store";

export default {
  name: "ScenarioAnalysis",
  filters: {
    formatNumber,
  },
  components: {
    SixScenarioAnalysis,
  },

  data() {
    return {
      selectedScenarioNumber: 1,
    };
  },
  props: {
    analysis: {
      type: Object,
    },
    currency: {
      type: String,
    },
    payload: {
      type: Array,
    },
  },
  computed: {
    analysisId() {
      return Number(this.$route.params.id);
    },
    selectedBridges() {
      return this.analysis.AnalysisBridges;
    },
    scenarios() {
      return store.state.bridge.scenarios.filter((s) => s.scenario_number > 0);
    },
    scenario() {
      return this.scenarios.find(x => x.scenario_number === this.selectedScenarioNumber)
    },
    sysCodeLanguage() {
      return this.$i18n.locale === "la" ? "description_lao" : "description_eng";
    },
    headers() {
      return [
        {
          text: this.$t("analysis-year"),
          value: "year",
          align: "right"

        },
        {
          text: this.$t("predictive-hi"),
          value: "hi",
          align: "right"

        },
        {
          text: `${this.$t("small-repair")} (${this.currency})`,
          value: "REPSM",
          align: "right"

        },
        {
          text: `${this.$t("medium-repair")} (${this.currency})`,
          value: "REPMD",
          align: "right"

        },
        {
          text: `${this.$t("large-repair")} (${this.currency})`,
          value: "REPLA",
          align: "right"

        },
        {
          text: `${this.$t("Year cost")} (${this.currency})`,
          value: "year_total_cost",
          align: "right"

        },
        {
          text: this.$t("Replace"),
          value: "number_recon",
          align: "right"
        },
        {
          text: `${this.$t("Cumulative cost")} (${this.currency})`,
          value: "cumulative_cost",
          align: "right"
        },
      ];
    },
    rawSeries() {
      return this.payload.filter(x => x.scenario_number === this.selectedScenarioNumber)
    },
    series() {
      return [
        {
          name: this.$t("cost"),
          data: this.rawSeries.map((s) => s.cumulative_cost),
          color: getSeriesColors("WORK"),
        },
        {
          name: this.$t("HI"),
          data: this.rawSeries.map((s) => s.hi),
          color: getSeriesColors("HI"),
        },
      ];
    },
    chartOptions() {
      const categories = this.rawSeries.map((s) => s.year);
      return {
        chart: {
          animations: {
            enabled: false,
          },
        },
        xaxis: {
          categories,
          labels: {
            formatter: function (value) {
              if (value % 5 === 0) {
                return value;
              }
              return "";
            },
          },
        },
        tooltip: {
          x: {
            formatter: function (value, { dataPointIndex }) {
              return categories[dataPointIndex];
            },
          },
        },
        markers: {
          size: 3,
        },
        yaxis: [
          {
            min: 0,
            labels: {
              formatter: function (val) {
                return formatNumber(val);
              },
            },
            title: {
              text: this.$t('Cumulative cost'),
            },
          },
          {
            opposite: true,
            labels: {
              formatter: function (val) {
                return formatNumber(val);
              },
            },

            min: 0,
            max: 100,
            title: {
              text: this.$t("HI"),
            },
          },
        ],
      };
    },
    additionalTitles() {
      return [
        ['Bridge Management System (BMS)'],
        ['Life Cycle Cost (LCC) Analysis'],
        [`Scenario ${this.scenario.scenario_number}`],
        []
      ]
    }
  },
  methods: {

  },
};
</script>

<style></style>