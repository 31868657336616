<template>
  <div class="d-flex flex-column">
    <v-snackbar v-model="snackbar" :timeout="3000" bottom dismissable right color="success">
      {{ snackbarText }}
      <v-icon @click="snackbar = false" class="pl-6">mdi-close-circle-outline</v-icon>
    </v-snackbar>
    <SixScenarioAnalysis :payload="payload" :analysis="analysis"
      :selected-scenario.sync="selectedScenario" :draft="analysis.draft" :currency="currency" />

    <div class="d-flex mt-2">
      <v-card class="pa-2">
        <v-toolbar flat>
          <v-toolbar-title>Budget constraints</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-toolbar-title>
            Planning period from {{ startYear }} to
            {{ startYear + internalYearlyBudget.length - 1 }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card class="d-flex ma-2">
          <v-text-field class="ma-2" v-model="budgetSeed" hide-details dense :readonly="!canEdit"></v-text-field>
          <v-btn color="primary" @click="applyToAllYear()" v-if="canEdit">Apply to all years</v-btn>
        </v-card>
        <div class="vertical-table-container">
          <div class="vertical-cell-container">
            <div class="header" :style="{ width: '150px' }">Year</div>
            <div class="header" :style="{ width: '165px' }">Needs ({{ currency }})</div>
            <div class="header" :style="{ width: '165px' }">Budget ({{ currency }})</div>
            <div class="header" :style="{ width: '165px' }"></div>
          </div>
          <div class="vertical-cell-container" v-for="(year, idx) in internalYearlyBudget" :key="idx">
            <div class="header" :style="{ width: '150px' }">
              {{ year.year }}
            </div>
            <div class="cell" :style="{ width: '165px' }">
              {{ getYearNeed(year.year) | formatNumber }}
            </div>
            <div class="cell disabled" :style="{ width: '165px' }">
              {{ year.budget | formatNumber }}
            </div>

            <div class="cell" :style="{ width: '165px' }">
              <v-text-field v-model="year.budget" hide-details dense :readonly="!canEdit"></v-text-field>
            </div>
          </div>
          <div class="ma-2" v-if="canEdit">
            <v-btn class="mr-1" color="red" small rounded @click="deleteLastYearlyBudget"
              :disabled="internalYearlyBudget.length === 1"><v-icon dark> mdi-minus </v-icon></v-btn>
            <v-btn color="indigo" small rounded @click="addYearlyBudget"><v-icon dark> mdi-plus </v-icon></v-btn>
          </div>
        </div>
      </v-card>
      <div class="ml-2" v-if="analysis">
        <v-card class="pa-2">
          <v-toolbar flat>
            <v-toolbar-title>Prioritization criteria</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
          <div class="vertical-table-container">
            <div class="vertical-cell-container">
              <div class="header" :style="{ width: '300px' }">Criteria</div>
              <div class="header" :style="{ width: '150px' }">Weight</div>
            </div>
            <div class="vertical-cell-container">
              <div class="header" :style="{ width: '300px' }">
                element_hi_weight
              </div>
              <div class="cell" :style="{ width: '150px' }">
                <v-text-field v-model="internalAnalysis.element_hi_weight" hide-details dense
                  :readonly="!canEdit"></v-text-field>
              </div>
            </div>
            <div class="vertical-cell-container">
              <div class="header" :style="{ width: '300px' }">
                element_importance_weight
              </div>
              <div class="cell" :style="{ width: '150px' }">
                <v-text-field v-model="internalAnalysis.element_importance_weight" hide-details dense
                  :readonly="!canEdit"></v-text-field>
              </div>
            </div>
            <div class="vertical-cell-container">
              <div class="header" :style="{ width: '300px' }">
                road_importance_weight
              </div>
              <div class="cell" :style="{ width: '150px' }">
                <v-text-field v-model="internalAnalysis.road_importance_weight" hide-details dense
                  :readonly="!canEdit"></v-text-field>
              </div>
            </div>
            <div class="vertical-cell-container">
              <div class="header" :style="{ width: '300px' }">
                traffic_volume_weight
              </div>
              <div class="cell" :style="{ width: '150px' }">
                <v-text-field v-model="internalAnalysis.traffic_volume_weight" hide-details dense
                  :readonly="!canEdit"></v-text-field>
              </div>
            </div>
            <div class="vertical-cell-container">
              <div class="header" :style="{ width: '300px' }">
                economic_impact_weight
              </div>
              <div class="cell" :style="{ width: '150px' }">
                <v-text-field v-model="internalAnalysis.economic_impact_weight" hide-details dense
                  :readonly="!canEdit"></v-text-field>
              </div>
            </div>
          </div>

          <div></div>
        </v-card>
      </div>
      <div class="ml-2">
        <v-card class="pa-2" v-if="canEdit">
          <v-btn color="primary" @click="save" :loading="saveLoading">Calculate yearly program</v-btn>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { formatNumber } from "@/helpers";
import ComputeService from "@/services/ComputeService";
import SixScenarioAnalysis from "@/components/analysis/SixScenarioAnalysis.vue";

export default {
  name: "BudgetConstraints",
  filters: {
    formatNumber,
  },
  components: {
    SixScenarioAnalysis,
  },
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      budgetNumberOfYears: 5,
      criterias: [
        {
          name: "Element health index",
          weight: 0.5,
        },
        {
          name: "Importance of element",
          weight: 0.2,
        },
        {
          name: "Road importance",
          weight: 0.1,
        },
        {
          name: "Traffic volume class",
          weight: 0.1,
        },
        {
          name: "Impact on regional economy",
          weight: 0.1,
        },
      ],
      budgetSeed: 0,
      internalAnalysis: {},
      saveLoading: false,
      selectedScenario: null,
      internalYearlyBudget: [],
    };
  },
  props: {
    payload: {
      type: Array,
      default() {
        return []
      }
    },
    currency: {
      type: String,
    },
    analysis: {
      type: Object,
    },
    canEdit: {
      type: Boolean,
    },
  },
  mounted() {
    this.internalAnalysis = { ...this.analysis };
    if (this.analysis.YearlyBudgets.length > 0) {
      this.internalYearlyBudget = [...this.analysis.YearlyBudgets];
    } else {
      this.resetBudgets();
    }
  },
  watch: {
    internalAnalysis: {
      handler() {
        this.$emit("update:internalAnalysis", this.internalAnalysis);
      },
      deep: true,
    },
    internalYearlyBudget: {
      handler() {
        this.$emit("update:yearlyBudgets", this.internalYearlyBudget);
      },
      deep: true,
    },
    startYear: {
      handler() {
        this.resetBudgets();
      },
    },
    selectedScenario: {
      handler(selectedScenario) {
        this.$emit("update:selectedScenario", selectedScenario);
      },
    },
  },
  computed: {
    selectedScenarioCosts() {
      if (this.analysis.scenario_number == null) {
        return [];
      }
      return this.payload.filter(
        (x) => x.scenario_number === this.analysis.scenario_number
      );
    },
    analysisId() {
      return Number(this.$route.params.id);
    },
    startYear() {
      return Number(this.analysis.start_year);
    },
    sysCodeLanguage() {
      return this.$i18n.locale === "la" ? "description_lao" : "description_eng";
    },
  },
  methods: {
    resetBudgets() {
      this.internalYearlyBudget = new Array(this.budgetNumberOfYears)
        .fill(Number(1))
        .map((x, idx) => ({
          analysis_id: this.analysisId,
          year: this.startYear + idx,
          budget: 0,
        }));
    },
    deleteLastYearlyBudget() {
      this.internalYearlyBudget.pop();
    },
    addYearlyBudget() {
      this.internalYearlyBudget.push({
        analysis_id: this.analysisId,
        year:
          this.internalYearlyBudget[this.internalYearlyBudget.length - 1].year +
          1,
        budget: 0,
      });
    },
    getYearNeed(year) {
      const need = this.selectedScenarioCosts.find((x) => x.year === year);
      return need ? need.year_total_cost : 0;
    },
    applyToAllYear() {
      this.internalYearlyBudget.forEach((x, idx) => {
        this.internalYearlyBudget[idx].budget = this.budgetSeed;
      });
    },
    async calculate() {
      try {
        this.calculating = true;
        await ComputeService.computeConstrainedWork(this.analysisId);
        this.calculating = false;
      } catch (e) {
        this.calculating = false;
      }
    },

    async save() {
      try {
        this.saveLoading = true;
        await this.calculate();

        this.$emit("update:analysis", {
          ...this.analysis,
          YearlyBudgets: this.internalYearlyBudget,
        });
        this.saveLoading = false;
        this.snackbarText = "Calculations successul.";
        this.snackbar = true;
      } catch (e) {
        this.snackbarText = "Calculations error.";
        this.snackbar = true;
        this.saveLoading = false;
      }
    },
  },
};
</script>

<style>
.min {
  background-color: red;
}

.disabled {
  background-color: #f5f5f5;
}
</style>