var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-dialog',{model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-layout',{attrs:{"child-flex":""}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2 d-flex align-center"},[_vm._v(" Work list "),_c('DataExport',{attrs:{"data":_vm.formattedWorkList,"headers":_vm.headers,"additional-titles":_vm.additionalTitles,"file-name":"Yearly Program"}})],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":{ 'items-per-page-text':''},"no-data-text":_vm.$t('No data available'),"headers":_vm.headers,"items":_vm.formattedWorkList,"items-per-page":100,"dense":""},scopedSlots:_vm._u([{key:"item.work_cost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.work_cost))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"blue","medium":""},on:{"click":function($event){return _vm.showSoundnessPage(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-note-text-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("popup-viewbridge")))])])]}}],null,true)})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Ok ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }