<template>
  <v-container fluid>
    <AreYouSureModal resource="analysis" prefix="custom" :id="selectedId" :dialog="deleteDialog" @toggle="toggleModal" />
    <div class="d-flex flex-column">
      <v-layout child-flex>
        <v-card class="ma-2 d-flex flex-column">
          <v-data-table :footer-props="{ 'items-per-page-text':''}" :no-data-text="$t('No data available')" :headers="headers" :items="draftAnalysis" :items-per-page="15" class="elevation-1">
            <template v-for="header in headers" v-slot:[`item.${header.value}`]="{ item }">
              <LinkToResource :key="header.value" :item="item" route="analysis-details">{{ item[header.value] }}
              </LinkToResource>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>{{ $t("Draft analysis") }}</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-btn @click="newAnalysis" color="green" dark v-if="$store.getters.get_access('module_analysis', 1)">
                  <v-icon left> mdi-plus </v-icon>
                  {{ $t("New analysis") }}</v-btn>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip top>
                <template v-slot:[`activator`]="{ on, attrs }" v-if="$store.getters.get_access('module_analysis', 1)">
                  <v-icon color="blue" medium class="mr-2" v-bind="attrs" v-on="on" @click="showDetails(item)">
                    mdi-note-text-outline
                  </v-icon>
                </template>
                <span>{{ $t("View analysis") }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:[`activator`]="{ on, attrs }" v-if="$store.getters.get_access('module_analysis', 3) || item.creator_id === userId">
                  <v-icon color="blue" medium class="mr-2" v-bind="attrs" v-on="on" @click="toggleModal(item.id)">
                    mdi-delete
                  </v-icon>
                </template>
                <span>Delete analysis</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-layout>
      <v-layout child-flex>
        <v-card class="ma-2 d-flex flex-column">
          <v-data-table :footer-props="{ 'items-per-page-text':''}" :no-data-text="$t('No data available')" :headers="headers" :items="publicAnalysis" :items-per-page="15" class="elevation-1">
            <template v-for="header in headers" v-slot:[`item.${header.value}`]="{ item }">
              <LinkToResource :key="header.value" :item="item" route="analysis-details">{{ item[header.value] }}
              </LinkToResource>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>{{ $t("Public analysis") }}</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-switch v-model="showArchived" label="Show archived"></v-switch>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip top>
                <template v-slot:[`activator`]="{ on, attrs }">
                  <v-icon color="blue" medium class="mr-2" v-bind="attrs" v-on="on" @click="showDetails(item)">
                    mdi-note-text-outline
                  </v-icon>
                </template>
                <span>View analysis</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:[`activator`]="{ on, attrs }" v-if="$store.getters.get_access('module_analysis', 3)">
                  <v-icon color="blue" medium class="mr-2" v-bind="attrs" v-on="on" @click="toggleModal(item.id)">
                    mdi-delete
                  </v-icon>
                </template>
                <span>Delete analysis</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-layout>
    </div>
  </v-container>
</template>
<script>
import BridgeService from "@/services/BridgeService";
import AreYouSureModal from "@/components/main/AreYouSureModal.vue";
import router from "@/router";
import LinkToResource from "@/components/main/LinkToResource.vue";

export default {
  name: "TheAnalysisList",
  components: {
    AreYouSureModal,
    LinkToResource,
  },
  data() {
    return {
      deleteDialog: false,
      showArchived: false,
      publicAnalysis: [],
      draftAnalysis: [],
      selectedId: null,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("description"),
          value: "description",
          sortby: true,
          color: "blue",
        },
        {
          text: this.$t("start_year"),
          value: "start_year",
          sortby: true,
          color: "blue",
        },
        {
          text: this.$t("number_of_years"),
          value: "number_of_years",
          sortby: true,
          color: "blue",
        },
        {
          text: this.$t("scenario_number"),
          value: "scenario_number",
          sortby: true,
          color: "blue",
        },
        { text: "", value: "actions", color: "blue" },
      ];
    },
    userId() {
      return this.$store.state.authentication.user.id
    }
  },
  methods: {
    async newAnalysis() {
      const savedItem = (
        await BridgeService.saveAnalysis({
          creator_id: this.userId,
          start_year: null,
          number_of_years: null,
          draft: true,
          element_hi_weight: 0.5,
          element_importance_weight: 0.3,
          road_importance_weight: 0.2,
          traffic_volume_weight: 0,
          economic_impact_weight: 0,
          AnalysisBridges: [],
          YearlyBudgets: [],
        })
      ).data;
      this.showDetails(savedItem);
    },
    async toggleModal(id) {
      if (!this.deleteDialog) {
        this.selectedId = id;
        this.deleteDialog = true;
      } else {
        this.selectedId = null;
        this.deleteDialog = false;
        this.getAnalysisList();
      }
    },
    showDetails(item) {
      router.push({
        name: "analysis-details",
        params: { id: item.id },
      });
    },
    async getAnalysisList() {
      const analysis = (await BridgeService.indexAnalysis()).data.sort(
        (a, b) => b.id - a.id
      );

      this.publicAnalysis = analysis.filter(
        (a) => a.draft === false && a.archived === false
      );

      this.draftAnalysis = analysis.filter(
        (a) => a.draft === true && a.archived === false
      );
    },
  },
  async mounted() {
    this.getAnalysisList();
  },
};
</script>
